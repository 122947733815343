// react
import React from 'react';
// gatsby
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql, Link } from 'gatsby';
// classNames
import clsx from 'clsx';
import styles from './coming.module.css';
// components
import Social from 'components/layout/footer/social';
import SEO from 'components/seo';
// meta
import { meta } from '../../metaData';

const Routes = require('../routes').Routes;

const CommingSoon = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImg: file(relativePath: { eq: "coming-soon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO mainMetaData={meta.comingSoon} />
      <div className={styles.grid}>
        <div className={clsx(styles.logoContainer, styles.columnLeft)}>
          <Link to="/" className={styles.logoLink} aria-label="Go to the home page" />
        </div>
        <BackgroundImage
          Tag="section"
          className={styles.img}
          fluid={data.bgImg.childImageSharp.fluid}
          preserveStackingContext
        />
        <div className={clsx(styles.titleContainer, styles.columnLeft)}>
          <h1 className={styles.title}>We are about to approve a case study for this project</h1>
          <Link to={Routes.CASE_STUDIES} className={styles.link}>
            View all cases
          </Link>
        </div>

        <div className={clsx(styles.socialContainer, styles.columnLeft)}>
          <p className={styles.follow}>Follow us</p>
          <Social customStyle={styles.social} />
        </div>
      </div>
    </>
  );
};

export default CommingSoon;
